<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("CATEGORY_EQUIPMENTS.ADD_CATEGORY_EQUIPMENT") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button @click="goBack" type="button" class="btn btn-sm btn-primary">
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <category-equipment-form
              :loading="loading"
              :categoryEquipmentData="categoryEquipment"
              :formErrors="formErrors"
              @categoryEquipmentSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultCategoryEquipment from "./defaultCategoryEquipment";
import CategoryEquipmentForm from "./partials/CategoryEquipmentForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    CategoryEquipmentForm,
  },

  mixins: [alertLeave],

  data() {
    const categoryEquipment = cloneDeep(defaultCategoryEquipment);
    return {
      categoryEquipment: categoryEquipment,
      formErrors: null,
      loading: false,
    };
  },

  methods: {
    goBack() {
      this.$router.push({ name: "List CategoryEquipments" });
    },

    viewCategoryEquipment(categoryEquipment) {
      this.alertLeave = false;
      this.$router.push({
        name: "View CategoryEquipment",
        params: { id: categoryEquipment.id },
      });
    },

    async handleSubmit(categoryEquipment) {
      this.loading = true;
      const categoryEquipmentData = cloneDeep(categoryEquipment);

      try {
        await this.$store.dispatch("categoryEquipments/add", categoryEquipmentData);
        this.$notify({
          type: "success",
          message: this.$t("CATEGORY_EQUIPMENTS.CATEGORY_EQUIPMENT_ADDED"),
        });
        const categoryEquipment = await this.$store.getters[
          "categoryEquipments/categoryEquipment"
        ];
        this.viewCategoryEquipment(categoryEquipment);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const categoryEquipment = await this.$store.getters[
          "categoryEquipments/categoryEquipment"
        ];
        if (categoryEquipment.id) {
          await this.$store.dispatch("categoryEquipments/destroy", categoryEquipment.id);
        }
        this.loading = false;
      }
    },
  },
};
</script>
